import React from 'react';
import { Routes, Route } from 'react-router-dom';
// components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';
import Works from './components/Works';

const App = () => {
  return (
    <div className="bg-site bg-no-repeat bg-cover overflow-hidden">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Banner />
              <Nav />
              <About />
              <Services />
              <Work />
              <Contact />
            </>
          }
        />
        <Route path="/works" element={<Works />} />
      </Routes>
    </div>
  );
};

export default App;
