import React from 'react';
// icons
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
// React Router
import { useNavigate } from 'react-router-dom';

// services data
const services = [
  {
    name: 'Frontend Development',
    description:
    'I create responsive and user-friendly interfaces using React.js, HTML, CSS, and Next.js, ensuring seamless navigation and engagement across devices.',
    link: 'Learn more',
  },
  {
    name: 'Backend Development',
    description:
    'I build scalable and secure backend systems with Node.js, Django, and Express.js, optimizing performance and reliability.',
    link: 'Learn more',
  },
  {
    name: 'Database Management',
    description:
    'I manage efficient databases with MongoDB, PostgreSQL, and Firebase, ensuring secure and fast data handling.',
    link: 'Learn more',
  },
  {
    name: 'Mobile Development',
    description:
    'I create high-performance, user-friendly mobile apps for Android and cross-platform solutions using Java, Kotlin, React Native, and Flutter.',
    link: 'Learn more',
  },
];

const Services = () => {
  const navigate = useNavigate(); // React Router navigate function

  const handleLearnMoreClick = () => {
    navigate('/works'); // Navigate to the works.js page
  };

  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text & image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0"
          >
            <h2 className="h2 text-accent mb-6">What I Do.</h2>
            <h3 className="h3 max-w-[455px] mb-16">
              I'm a Full Stack Developer with over 5 years of experience.
            </h3>
            <button className="btn btn-sm" onClick={handleLearnMoreClick}>See my work</button>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            {/* service list */}
            <div>
              {services.map((service, index) => (
                <div
                  className="border-b border-white/20 h-[146px] mb-[38px] flex"
                  key={index}
                >
                  <div className="max-w-[476px]">
                    <h4 className="text-[20px] tracking-wider font-primary font-semibold mb-6">
                      {service.name}
                    </h4>
                    <p className="font-secondary leading-tight">{service.description}</p>
                  </div>
                  <div className="flex flex-col flex-1 items-end">
                    <button
                      onClick={handleLearnMoreClick} // Navigate to works.js
                      className="btn w-9 h-9 mb-[42px] flex justify-center items-center"
                    >
                      <BsArrowUpRight />
                    </button>
                    <button
                      onClick={handleLearnMoreClick} // Navigate to works.js
                      className="text-gradient text-sm"
                    >
                      {service.link}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
