import React from 'react';
// CountUp
import CountUp from 'react-countup';
// Intersection Observer Hook
import { useInView } from 'react-intersection-observer';
// Motion
import { motion } from 'framer-motion';
// Variants
import { fadeIn } from '../variants';
// React Router
import { useNavigate } from 'react-router-dom';
// React Scroll
import { Link } from 'react-scroll';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const navigate = useNavigate(); // For navigation to works.js

  const handlePortfolioClick = () => {
    navigate('/works'); // Navigates to the works.js route
  };

  return (
    <section className="section" id="about" ref={ref}>
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen">
          {/* Image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top"
          ></motion.div>
          {/* Text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <h2 className="h2 text-accent">About me.</h2>
            <h3 className="h3 mb-4">
              I'm a Full-Stack Developer with over 5 years of experience.
            </h3>
            <p className="mb-6">
              Over the years, I've collaborated with cross-functional teams to deliver 20+ successful projects, earning the trust of 15k+ satisfied clients globally.
            </p>
            {/* Stats */}
            <div className="flex gap-x-6 lg:gap-x-10 mb-12">
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={5} duration={3} /> : null}
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Years of <br />
                  Experience
                </div>
              </div>
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={15} duration={3} /> : null}k+
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Satisfied <br />
                  Clients
                </div>
              </div>
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={20} duration={3} /> : null}
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Projects <br />
                  Completed
                </div>
              </div>
            </div>
            {/* Buttons */}
            <div className="flex gap-x-8 items-center">
              {/* Contact me Button */}
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className="btn btn-lg flex items-center justify-center cursor-pointer"
              >
                Contact me
              </Link>
              {/* My Portfolio Button */}
              <button
                onClick={handlePortfolioClick}
                className="text-gradient btn-link flex items-center justify-center"
              >
                My Portfolio
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
