import React from 'react';
// images
import Image from '../assets/avatar.svg';
// icons
import { FaGithub, FaYoutube, FaDribbble } from 'react-icons/fa';
// type animation
import { TypeAnimation } from 'react-type-animation';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
// react-scroll
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom'; // For navigation to the "My Portfolio" page

const Banner = () => {
  const navigate = useNavigate(); // React Router's navigate function

  const handlePortfolioClick = () => {
    navigate('/works'); // Navigates to the works.js page
  };

  return (
    <section className="min-h-[85vh] lg:min-h-[78vh] flex items-center" id="home">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
          {/* text */}
          <div className="flex-1 text-center font-secondary lg:text-left">
            <h1 className="text-[55px] font-bold leading-[0.8] lg:text-[110px]">
              AHMED <span>SHAIKH</span>
            </h1>
            <div className="mb-6 text-[36px] lg:text-[60px] font-secondary uppercase leading-[1]">
              <span className="text-white mr-4">I am a</span>
              <TypeAnimation
                sequence={[
                  'Developer',
                  2000,
                  'Designer',
                  2000,
                  'Tutor',
                  2000,
                ]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </div>
            <p className="mb-8 max-w-lg mx-auto lg:mx-0">
              I am a passionate full-stack developer, designer, and coding tutor, creating high-quality websites and mobile apps. I focus on building secure, scalable solutions while sharing my expertise to empower others in coding and design.
            </p>
            <div className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0">
              {/* Contact Me Button */}
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className="btn btn-lg flex items-center justify-center cursor-pointer"
              >
                Contact me
              </Link>
              {/* My Portfolio Button */}
              <button
                onClick={handlePortfolioClick}
                className="text-gradient btn-link flex items-center justify-center"
              >
                My Portfolio
              </button>
            </div>
            {/* socials */}
            <div className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0">
              <a href="#">
                <FaYoutube />
              </a>
              <a href="#">
                <FaGithub />
              </a>
              <a href="#">
                <FaDribbble />
              </a>
            </div>
          </div>
          {/* image */}
          <div className="hidden lg:flex flex-1 max-w-[482px] lg:max-w-[600px] -mt-10">
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
