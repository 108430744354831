import React, { useEffect } from 'react';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
// images
import Img1 from '../assets/portfolio-img1.png';
import Img2 from '../assets/portfolio-img2.png';
import Img3 from '../assets/portfolio-img3.png';

const Works = () => {
  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Personal Projects - Websites
  const websites = [
    {
      title: 'Prime US Builders App and Website',
      description:
        'A seamless platform for contractor services built with Node.js and React.js.',
      image: Img1,
      link: 'https://premierbuilders.us/',
      repo: 'https://github.com/ahmedmuhamd20/',
    },
    {
      title: 'Online Store for Electronic Appliances',
      description:
        'E-commerce platform for MIDEA Company specializing in air treatment and home appliances.',
      image: Img2,
      link: 'https://mideapakistan.com/',
    },
  ];

  // Personal Projects - Mobile Apps
  const apps = [
    {
      title: 'Diagnostic & Research Lab Mobile App',
      description:
        'Access past lab reports and provide feedback using Java (Android Studio) and Node.js.',
      image: Img3,
      link: 'https://drlab.lumhs.edu.pk/index.php',
      repo: 'https://github.com/ahmedmuhamd20/',
    },
    {
      title: 'Global Course Finder Application',
      description:
        'Search and compare academic programs worldwide by location, fees, and preferences.',
      image: Img1,
      repo: 'https://github.com/ahmedmuhamd20/',
    },
    {
      title: 'Rovinj Security',
      description:
        'Real-time tracking and incident reporting app for security guards using Java, React.js, Node.js, and Firebase.',
      image: Img2,
      link: 'https://rovajsecurity.b1-media.hr/admin/',
      repo: 'https://github.com/ahmedmuhamd20/',
    },
  ];

  return (
    <section className="bg-site bg-no-repeat bg-cover min-h-screen p-5" id="works">
      <div className="container mx-auto">
        {/* Page Title */}
        <h1 className="text-4xl text-center mb-10 text-accent">
          My Work
        </h1>

        {/* Websites Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-10 text-white">Websites</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {websites.map((website, index) => (
              <motion.div
                key={index}
                variants={fadeIn('up', 0.3)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.3 }}
                className="group relative overflow-hidden border-2 border-white/50 rounded-xl bg-gray-800"
              >
                {/* Image */}
                <div className="overflow-hidden">
                  <img
                    className="group-hover:scale-125 transition-all duration-500 w-full h-64 object-cover"
                    src={website.image}
                    alt={website.title}
                  />
                </div>
                {/* Text Section */}
                <div className="p-5">
                  <h4 className="text-xl font-bold text-white mb-2">{website.title}</h4>
                  <p className="text-gray-300 mb-4">{website.description}</p>
                  {/* Links */}
                  <div className="flex gap-4">
                    {website.link && (
                      <a
                        href={website.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-accent underline"
                      >
                        Website
                      </a>
                    )}
                    {website.repo && (
                      <a
                        href={website.repo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-accent underline"
                      >
                        GitHub
                      </a>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Mobile Apps Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-10 text-white">Mobile Apps</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {apps.map((app, index) => (
              <motion.div
                key={index}
                variants={fadeIn('up', 0.3)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.3 }}
                className="group relative overflow-hidden border-2 border-white/50 rounded-xl bg-gray-800"
              >
                {/* Image */}
                <div className="overflow-hidden">
                  <img
                    className="group-hover:scale-125 transition-all duration-500 w-full h-64 object-cover"
                    src={app.image}
                    alt={app.title}
                  />
                </div>
                {/* Text Section */}
                <div className="p-5">
                  <h4 className="text-xl font-bold text-white mb-2">{app.title}</h4>
                  <p className="text-gray-300 mb-4">{app.description}</p>
                  {/* Links */}
                  <div className="flex gap-4">
                    {app.link && (
                      <a
                        href={app.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-accent underline"
                      >
                        Website
                      </a>
                    )}
                    {app.repo && (
                      <a
                        href={app.repo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-accent underline"
                      >
                        GitHub
                      </a>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Publications Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-10 text-white">Publications</h2>
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-700 text-gray-300">
              <thead>
                <tr className="bg-gray-800 text-white">
                  <th className="border border-gray-700 px-4 py-2 text-left">#</th>
                  <th className="border border-gray-700 px-4 py-2 text-left">Title</th>
                  <th className="border border-gray-700 px-4 py-2 text-left">DOI</th>
                  <th className="border border-gray-700 px-4 py-2 text-left">Authors</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-700 px-4 py-2">1</td>
                  <td className="border border-gray-700 px-4 py-2">
                    Succeeding Generation Of Augmented Reality Technology In Medical Education
                  </td>
                  <td className="border border-gray-700 px-4 py-2">
                    <a
                      href="https://doi.org/10.38106/LMRJ.2023.5.2-07"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent underline"
                    >
                      DOI: 10.38106/LMRJ.2023.5.2-07
                    </a>
                  </td>
                  <td className="border border-gray-700 px-4 py-2">
                    Mehran Sayed, Shoaib Wadho, Azar Akbar Memon, Ahmed Muhammad Shaikh, Zia Ahmed Shaikh
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-700 px-4 py-2">2</td>
                  <td className="border border-gray-700 px-4 py-2">
                    Blockchain In Healthcare: Unlocking The Potential Of Blockchain For Secure And Efficient Applications For Medical Data Management
                  </td>
                  <td className="border border-gray-700 px-4 py-2">
                    <a
                      href="https://doi.org/10.38106/LMRJ.2023.5.2-08"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent underline"
                    >
                      DOI: 10.38106/LMRJ.2023.5.2-08
                    </a>
                  </td>
                  <td className="border border-gray-700 px-4 py-2">
                    Zia Ahmed Shaikh, Azar Akbar Memon, Ahmed Muhammad Shaikh, Samiullah Soomro, Mehran Sayed
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-700 px-4 py-2">3</td>
                  <td className="border border-gray-700 px-4 py-2">
                    Rise In Mobile Gadgets Use For School Learning And Health Issues Of Children
                  </td>
                  <td className="border border-gray-700 px-4 py-2">
                    <a
                      href="https://doi.org/10.38106/LMRJ.2022.4.3-06"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent underline"
                    >
                      DOI: 10.38106/LMRJ.2022.4.3-06
                    </a>
                  </td>
                  <td className="border border-gray-700 px-4 py-2">
                    Nabia Shah, Ahmed Muhammad Shaikh, Fasiha Shah
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-700 px-4 py-2">4</td>
                  <td className="border border-gray-700 px-4 py-2">
                    Automated Facial Animation Using Marker Point for Motion Extraction (Submitted)
                  </td>
                  <td className="border border-gray-700 px-4 py-2">N/A</td>
                  <td className="border border-gray-700 px-4 py-2">Ahmed Muhammad Shaikh</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Works;
