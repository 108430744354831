import React from 'react';
// images
import Logo from '../assets/logo.png';

const Header = () => {
  return (
    <header className="py-8">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          {/* logo */}
          <a href="/">
            <img src={Logo} alt="Logo" />
          </a>
          {/* LinkedIn button */}
          <a
            href="https://www.linkedin.com/in/ahmed-muhammad-shaikh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn-sm">LinkedIn</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
